import {
  TDashboardInfoResponse,
  TDashboardRatingResponse,
  TDashboardCoursesInfoResponse,
  TDashboardTracksInfoResponse,
  TDashboardDeadlineInfoResponse,
} from 'types/dashboardApiTypes';
import api from './api';

interface ISearchParams {
  skip: number;
  take: number;
  groupId?: number;
}

const getDashboardInfo = (): Promise<TDashboardInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/settings');

const getDashboardRating = (groupId: number | null): Promise<TDashboardRatingResponse> => {
  const searchParams: ISearchParams = {
    skip: 0,
    take: 5,
  };
  if (groupId) {
    searchParams.groupId = groupId;
  }
  return api.privateRequest('get', `api/web/tlrocks/v2/dashboard/data/rating`, {
    searchParams: searchParams as unknown as Record<string, string | number | boolean>,
  });
};

const getDashboardCourses = (): Promise<TDashboardCoursesInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/data/courses');

const getDashboardTracks = (): Promise<TDashboardTracksInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/data/tracks');

const getDashboardLearningSummary = (): Promise<TDashboardDeadlineInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/data/learningSummary');

export default {
  getDashboardInfo,
  getDashboardRating,
  getDashboardCourses,
  getDashboardTracks,
  getDashboardLearningSummary,
};
