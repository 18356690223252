import { types } from 'mobx-state-tree';
import { ParticipationDeadlineStatus } from '_constants';

export enum DashboardElementTypes {
  RATING = 'rating',
  COURSES = 'courses',
  TRACKS = 'tracks',
  LEARNING_SUMMARY = 'learningSummary',
}

export enum RatingTypes {
  COMMON = 'common',
  GROUPS = 'groups',
  COMMON_GROUPS = 'commonGroups',
}

export enum LearningSummaryStatus {
  NOT_STARTED = 'notStarted',
  IN_TIME = 'inTime',
  SLOW = 'slow',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
}

export enum OpenTrainingSections {
  COURSES = 'openCourses',
  TRACKS = 'openTracks',
  NONE = 'none',
}

const MDashboard = types.model({
  type: types.maybeNull(types.enumeration<DashboardElementTypes>('Type', Object.values(DashboardElementTypes))),
  name: types.maybeNull(types.string),
  settings: types.maybeNull(
    types.model({
      showScore: types.maybeNull(types.boolean),
      showPositionInRating: types.maybeNull(types.boolean),
      eventGamificationGroups: types.array(types.number),
      ratingType: types.enumeration<RatingTypes>('Type', Object.values(RatingTypes)),
    }),
  ),
});

export const MDashboardInfo = types.array(MDashboard);

export const MDashboardUserRating = types.model({
  score: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  scoreTitle: types.string,
  scoreUnit: types.maybeNull(types.string),
});

export const MDashboardRatingGroups = types.model({
  id: types.maybeNull(types.number),
  name: types.string,
});

export const MDashboardRatings = types.model({
  id: types.number,
  fullName: types.string,
  imagePath: types.maybeNull(types.string),
  points: types.number,
  rank: types.number,
});

export const MDashboardRating = types.model({
  authorizedUserRating: types.maybeNull(MDashboardUserRating),
  ratingGroups: types.maybeNull(types.array(MDashboardRatingGroups)),
  ratings: types.maybeNull(types.array(MDashboardRatings)),
});

export enum DashboardCourseTrackStatuses {
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
}

export const MDashboardCourseInfo = types.model({
  id: types.number,
  openDate: types.maybeNull(types.string),
  assignmentDate: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  coverUrl: types.maybeNull(types.string),
  coverThumbnailUrl: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  stagesCount: types.number,
  stagesPassed: types.number,
  status: types.maybeNull(
    types.enumeration<DashboardCourseTrackStatuses>('Status', Object.values(DashboardCourseTrackStatuses)),
  ),
  viewInAdminMode: types.maybeNull(types.boolean),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
  deadlineDate: types.maybeNull(types.string),
});

export const MDashboardTrackInfo = types.model({
  id: types.string,
  name: types.string,
  coverUrl: types.string,
  coverThumbnailUrl: types.string,
  status: types.enumeration<DashboardCourseTrackStatuses>('Status', Object.values(DashboardCourseTrackStatuses)),
  totalSteps: types.number,
  completedSteps: types.number,
  viewInAdminMode: types.boolean,
  openDate: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
  deadlineDate: types.maybeNull(types.string),
});

export const MDashboardLearningSummaryInfo = types.model({
  title: types.string,
  status: types.enumeration<LearningSummaryStatus>('Status', Object.values(LearningSummaryStatus)),
  action: types.enumeration<OpenTrainingSections>('Action', Object.values(OpenTrainingSections)),
  text: types.string,
});
