/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import {
  MDashboardCourseInfo,
  MDashboardTrackInfo,
  MDashboardInfo,
  MDashboardRating,
  MDashboardLearningSummaryInfo,
} from 'models';
import dashboardApi from 'api/dashboardApi';
import { TDashboardInfoResponse, TDashboardRatingResponse } from 'types/dashboardApiTypes';
import { DashboardElementTypes } from 'models/MDashboard';

const Dashboard = types
  .model({
    data: types.maybeNull(MDashboardInfo),
    rating: types.maybeNull(MDashboardRating),
    courses: types.maybeNull(types.array(MDashboardCourseInfo)),
    tracks: types.maybeNull(types.array(MDashboardTrackInfo)),
    learningSummary: types.maybeNull(MDashboardLearningSummaryInfo),
    groupId: types.maybeNull(types.number),
    isLoading: false,
  })
  .views((self) => ({
    get ratingInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.RATING);
    },
    get coursesInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.COURSES);
    },
    get tracksInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.TRACKS);
    },
    get learningSummaryInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.LEARNING_SUMMARY);
    },
  }))
  .actions((self) => ({
    setGroupId: (id: number | null) => {
      self.groupId = id;
    },
  }))
  .actions((self) => ({
    fetchDashboardInfo: flow(function* fetchDashboardInfo() {
      self.setGroupId(null);
      const { data, hasError }: TDashboardInfoResponse = yield dashboardApi.getDashboardInfo();
      if (!hasError && data) {
        self.data = cast(data);
      }
      const ratingPromise = self.ratingInfo ? dashboardApi.getDashboardRating(self.groupId) : Promise.resolve(null);
      const coursesPromise = self.coursesInfo ? dashboardApi.getDashboardCourses() : Promise.resolve(null);
      const tracksPromise = self.tracksInfo ? dashboardApi.getDashboardTracks() : Promise.resolve(null);
      const learningSummaryPromise = self.learningSummaryInfo
        ? dashboardApi.getDashboardLearningSummary()
        : Promise.resolve(null);

      const [ratingResponse, coursesResponse, tracksResponse, learningSummaryResponse] = yield Promise.all([
        ratingPromise,
        coursesPromise,
        tracksPromise,
        learningSummaryPromise,
      ]);

      if (ratingResponse && !ratingResponse.hasError && ratingResponse.data) {
        self.rating = cast(ratingResponse.data);
      }

      if (coursesResponse && !coursesResponse.hasError && coursesResponse.data) {
        self.courses = cast(coursesResponse.data);
      }

      if (tracksResponse && !tracksResponse.hasError && tracksResponse.data) {
        self.tracks = cast(tracksResponse.data);
      }

      if (learningSummaryResponse && !learningSummaryResponse.hasError && learningSummaryResponse.data) {
        self.learningSummary = cast(learningSummaryResponse.data);
      }
    }),
    fetchRatingInfo: flow(function* fetchRatingInfo() {
      self.isLoading = true;
      const { data, hasError }: TDashboardRatingResponse = yield dashboardApi.getDashboardRating(self.groupId);
      if (!hasError && data) {
        self.rating = cast(data);
      }
      self.isLoading = false;
    }),
  }));

export default Dashboard;
